import React, { useEffect } from "react"
import Meta from "../utilities/seo"

const Ele = () => {
  useEffect(() => {
    window.location.href =
      "https://invest.myxeno.com?utm_source=email&utm_medium=promotions&utm_campaign=autosave_bank_reveal"
  }, [])
  return (
    <>
      <Meta title="AutoSave" />
    </>
  )
}

export default Ele
